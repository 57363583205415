/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #30404d; }
  html .main-container,
  body .main-container,
  #root .main-container {
    margin-top: 1rem; }

.application {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

:focus,
.bp3-control input[type="checkbox"]:focus ~ .bp3-control-indicator {
  outline: none; }
